import { createCsv } from 'src/app/common/createCsv';
import {TareasApiService} from '../../../../service/api/tareas-api.service';
import { Component } from '@angular/core';
import { Router} from '@angular/router';
import {DashboardService} from '../../../../service/events/dashboard.service';
import {InputType} from '../../../../common/components/form-builder/form-builder.component';
import {RequestHandler} from '../../../../service/OffService/request-handler';
import {RequestButtonTypes} from '../../../../common/components/request-button/request-button.component';
import {BaseView} from '../../../base-view';
import moment, { Moment } from 'moment';
import { environment } from 'src/environments/environment';
import { Filtering } from 'src/app/service/filtering/filtering';
import {StorageManager} from '../../../../common/storage-manager.class';

import type { OnInit, AfterViewInit } from '@angular/core';
import type { ItemInterface } from '../../../../common/components/form-builder/form-builder.component';
import { TareaLimpiezaGeneralModel } from 'src/app/models/tareas/tarea-limpieza-general.model';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';
import { TreeNode } from 'primeng/api';

const TRUTHY_VALUES = [1, '1', true, 'true'];
@Component({
    selector: 'app-tareas-mto-limpieza-general',
    templateUrl: './tareas-mto-limpieza-general.component.html',
    styleUrls: ['./tareas-mto-limpieza-general.component.scss']
})
export class TareasMtoLimpiezaGeneralComponent extends BaseView<TareaLimpiezaGeneralModel> implements OnInit, AfterViewInit {

    public rolUser = StorageManager.getUser().rol;
    public hiddenButtonDelete = false;

    public appName = environment.appName;
    public fincaName = environment.features.fincaName; 
    public hasClients = environment.features.hasClients;
    public serverUrl = environment.serverUrl;
    public userCanSeeClientes = this.hasClients &&
  (environment.features.userCanSeeClientes ? true : (StorageManager.getUser().rol === 'admin'));     

    public getRequest: RequestHandler<TareaLimpiezaGeneralModel[]> = this.tareasApi.mtoLimpiezaGeneral.GET;
    public deleteRequest: RequestHandler<TareaLimpiezaGeneralModel> = this.tareasApi.mtoLimpiezaGeneral.DELETE;
    public pathToForm = 'limpieza-general';  
    public menuItemId = 'limpieza-general';  

    public override filtering: Filtering<TareaLimpiezaGeneralModel> = new Filtering<TareaLimpiezaGeneralModel>();
    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;  

    public override canShow: Record<string, boolean> = {};
    public override model: TareaLimpiezaGeneralModel = {};
  
    public distributionOrder = [3];
    public formFields: ItemInterface<object>[] = 
        [
            {
                field: 'notas_cliente',
                label: 'Notas cliente',
                inputType: {type: InputType.TEXTAREA},
                visible: environment.features.showTareasMto ? true : false
            },
            {
                field: 'cliente_nombre', 
                name: 'Cliente', 
                label: 'Cliente', inputType: {type: InputType.TEXT}
            },
            {
                field: 'fecha', 
                name: 'Fecha', 
                label: 'Fecha', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'hora_inicio', 
                name: 'Hora Inicio', 
                label: 'Hora Inicio', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'hora_fin', 
                name: 'Hora Fin', 
                label: 'Hora Fin', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'horas_totales', 
                name: 'Horas', 
                label: 'Horas', 
                inputType: {type: InputType.TEXT} 
            },
            {
                field: 'finca', 
                name: this.fincaName, 
                label: this.fincaName, 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'mantenimiento', 
                name: 'Mantenimiento/Creación', 
                label: 'Mantenimiento', 
                inputType: {type: InputType.RO_MD_SWITCH}
            },
            {
                field: 'prioridad', 
                name: 'Prioridad/Realizada', 
                label: 'Prioridad/Realizada', 
                inputType: {type: InputType.TEXT}
            }
        ];  

    public formFieldsSimple: ItemInterface<object>[] = 
        [
            {
                field: 'limpieza_calles', 
                name: 'Limpieza de Calles', 
                label: 'Limpieza de Calles', 
                inputType: {type: InputType.RO_MD_SWITCH}
            },
            {
                field: 'bolsas_basura', 
                name: 'Cambio de Bolsas de Basura', 
                label: 'Cambio de Bolsas de Basura', 
                inputType: {type: InputType.RO_MD_SWITCH}
            },
            {
                field: 'limpieza_desagues', 
                name: 'Limpieza de Desagües', 
                label: 'Limpieza de Desagües', 
                inputType: {type: InputType.RO_MD_SWITCH }
            }
        ];  

    public formFieldsTrabajadores: ItemInterface<object>[] = [
        {
            field: 'trabajadores_nombres', 
            name: 'Profesional(es)', 
            label: 'Profesional(es)', 
            inputType: {type: InputType.TEXTAREA}
        }, 
    ];
  
    public formFieldsOrdenTrabajo: ItemInterface<object>[] = 
        [  
            {
                field: 'orden_trabajo', 
                name: 'Orden de trabajo', 
                label: 'Orden de trabajo', 
                inputType: {type: InputType.TEXTAREA}
            }
        ];

    public formFieldsObs: ItemInterface<object>[] = 
        [  
            {
                field: 'observaciones', 
                name: 'Observaciones', 
                label: 'Observaciones', 
                inputType: {type: InputType.TEXTAREA}
            },
        ];
  
    public cols: ColInterface[] = [
        {field: 'cliente_nombre', header: 'Cliente', filter: ''},
        {field: 'trabajadores_nombres', header: 'Empleado', filter: ''},
        {field: 'finca', header: this.fincaName, filter: ''},
        {field: 'fecha', header: 'Fecha', filter: ''},
        {field: 'hora_inicio', header: 'Hora Inicio', filter: ''},
        {field: 'hora_fin', header: 'Hora Fin', filter: ''},
        {field: 'horas_totales', header: 'Horas', filter: ''},
        {field: 'prioridad', header: 'Prioridad/Realizada', filter: ''},
        {field: 'observaciones', header: 'Observaciones', filter: ''}
    ];  

    public prioridad = {
        selected: ''
    };

    public totalHoras: Moment | string = '00:00';
    public auxTotalHoras: Moment | string = '00:00';
    public fecha: {inicio: string; fin: string} = {
        inicio: '',
        fin: '',
    };

    constructor(public tareasApi: TareasApiService,
        private dashboard: DashboardService,
        private router: Router) {
        super(dashboard, tareasApi.mtoLimpiezaGeneral.GET, tareasApi.mtoLimpiezaGeneral.DELETE);
  
  
    }

    ngOnInit() {
      
        if (environment.features.showTareasMto){
            if (this.rolUser !== 'admin'){
                this.hiddenButtonDelete = true;
            }
            this.prioridad.selected = 'No-Realizada';
        }
    
        this.dashboard.setSubmenuItem(this.menuItemId);
    
        this.filtering.addFilter((copy: TreeNode<TareaLimpiezaGeneralModel>[] | void) => 
            (copy as TreeNode<TareaLimpiezaGeneralModel>[]).filter(row => {
                const tareaLimpieza = row.data as TareaLimpiezaGeneralModel;

                switch (this.prioridad.selected) {
                case 'Realizada':
                    return ['Realizada'].includes(tareaLimpieza.prioridad ?? '');
                case 'No-Realizada':
                    return !['Realizada'].includes(tareaLimpieza.prioridad ?? '');
                default:
                    return true;
                }
            }));

        /*** 
   *  En el caso de que el usuario no se admin,
   * filtrará por su id de usuario que coincide,
   *  con el id trabajador   
  */
        if ( StorageManager.getUser().rol !== 'admin' ){ 
            this.filtering.addFilter((copy: TreeNode<TareaLimpiezaGeneralModel>[] | void) => 
                (copy as TreeNode<TareaLimpiezaGeneralModel>[]).filter(row => {
                    const tareaLimpieza = row.data as TareaLimpiezaGeneralModel;

                    if (tareaLimpieza.ids_trabajadores != null ) {
          
                        const idTrabajador = StorageManager.getUser().id;
                        const listaTrabajadores = (tareaLimpieza.ids_trabajadores as string).split(';');
                        if ( listaTrabajadores.includes(idTrabajador)) {
                            return true;
                        }
          
                    }
                    return false;
                }));
        }

        /*** 
   *  Fin de filtrar por id_usuario = trabajador,
   *   
  */
        this.filtering.addFilter((copy: TreeNode<TareaLimpiezaGeneralModel>[] | void) => 
            (copy as TreeNode<TareaLimpiezaGeneralModel>[]).filter(row => {
                const tareaLimpieza = row.data as TareaLimpiezaGeneralModel;

                if (this.fecha.inicio && this.fecha.fin && tareaLimpieza.fecha) {

                    const arrayFecha = tareaLimpieza.fecha.toString().split('/');
                    const fechaAux = arrayFecha[2] + '-' + arrayFecha[1] + '-' + arrayFecha[0];

                    if (
                        (moment(moment(this.fecha.inicio).format('YYYY-MM-DD')).diff(fechaAux) <= 0) && 
                    (moment(moment(this.fecha.fin).format('YYYY-MM-DD')).diff(fechaAux) >= 0)
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return true;
                }

            }));
    }

    ngAfterViewInit() {
        this.tareasApi.mtoLimpiezaGeneral.GET.response(() => {
            this.updateFooter();
            this.sum();
        });
    }

    public add() {
        if (!StorageManager.getClient() && this.userCanSeeClientes) {
            this.dashboard.openClientDialog();
        } else {
            this.router.navigate(['dashboard', 'crear-' + this.pathToForm]);
        }
    }
  
    public edit(data: TareaLimpiezaGeneralModel) {
        this.router.navigate(['dashboard', 'editar-limpieza-general', data.id]);
    }
  
    public duplicate(data: TareaLimpiezaGeneralModel) {
        this.router.navigate(['dashboard', 'duplicar-limpieza-general', data.id]);
    }  

    public override deleteRegister() {
        if (this.model.id) {
            this.deleteRequest.safePerform(this.model);
            this.deleteRequest.response(() => {
                if (!this.deleteRequest.hasError) {
                    this.getRequest.safePerform();
                }
            });
        } else {
            this.deleteRequest.hasError = true;
            throw new Error('Trying to DELETE a register without primary key');
        }
    }  

    public override show(modal: string, data: TareaLimpiezaGeneralModel) {
        let x;
        this.formFields.forEach( form => {
            if ( form.field === 'mantenimiento' ){
                if (data.mantenimiento) {
                    form.label = 'Creación';
                } else {
                    form.label = 'Mantenimiento';
                }
            }
        });

        const trueFields = [
            'mantenimiento',
            'limpieza_calles',
            'bolsas_basura',
            'limpieza_desagues'
        ];
        trueFields.forEach( f => {
            data[f] = TRUTHY_VALUES.includes(data[f]?.toString() ?? '');
        });
        this.model = data;

        this.canShow[modal] = true;

        for (x in this.canShow) {
            if (x !== modal && this.canShow[x]) {
                this.canShow[x] = false;
            }
        }
    }  

    public override hide(modal: string) {
        this.model = {};
        this.canShow[modal] = false;
        this.deleteRequest.reset();
    }

    public globalConditionalStyle(value: string, col: ColInterface): ColStylesInterface {
        if ( col.field === 'horas_totales') {
            return {
                textAlign: 'center'
            };
        }
        if (col.field === 'prioridad') {
            if (value === 'Alta') {
                return {
                    rowStyle: {
                        backgroundColor: '#f44242'
                    },  
                };
            }
            else if (value === 'Realizada'){
                return {
                    rowStyle: {
                        backgroundColor: '#00ff00'
                    },  
                };
            }
        }

        if (col.field === 'trabajadores_nombres') {
            return {
                style: {
                    'max-width': '50px',
                }
            };
        }
        if (col.field === 'observaciones') {
            return {
                style: {
                    'max-width': '50px',
                }
            };
        }
        return {};
    }
  
    public globalVisualTransform(value: string, col: ColInterface) {
        const fieldsSiNo = ['limpieza_calles', 'bolsas_basura', 'limpieza_desagues'];
        if ( fieldsSiNo.includes(col.field)) {
            if ( TRUTHY_VALUES.includes(value) ) {
                return 'Sí';
            } else {
                return 'No';
            }
        }
        return value;
    }
  
    public formatDate(date: string) {
        moment.locale('es');
        return moment(date, 'DD/MM/YYYY').format('LL');
    }  

    public filtrarRealizados(value: string, from = 'prioridad') {
        if (from === 'prioridad') {
            this.prioridad.selected = this.prioridad.selected === value ? '' : value;
        } 

        this.filtering.filter();
    }
  
    public async downloadCsv() {
  
        const arrayFormFields = [
            this.formFields,
            this.formFieldsSimple,
            this.formFieldsTrabajadores,
            this.formFieldsOrdenTrabajo,
            this.formFieldsObs,
        ];

        const csvContent = createCsv(arrayFormFields, this.filtering.value, 'observaciones');

        const csvLink = document.getElementById('csvLink') as HTMLAnchorElement | null;

        if (csvLink) {
            csvLink.download = 'Limpieza general.csv';
            csvLink.href = encodeURI(csvContent);
            csvLink.click();
        }// if();

    }


    public override updateFooter() {
        this.sum();
    }

    public override sum() {
        this.totalHoras = moment(new Date('October 1, 2014 00:00:00'));
        this.auxTotalHoras = moment(new Date('October 1, 2014 00:00:00'));
        let contador = 0;
        (this.filtering.value || []).forEach((tareas, index ) => {

            if ( tareas.data?.horas_totales != null ) {
   
                if (tareas.data.horas_totales.length > 0) {
                    contador++;
                    const horaMinuto = (tareas.data.horas_totales as string).split(':');
                    this.totalHoras = moment(this.totalHoras).add(horaMinuto[0], 'hours').add(horaMinuto[1], 'minutes');
                }
            }

            if ( index + 1 >= (this.filtering.value as TareaLimpiezaGeneralModel[]).length ){
  
                let minutos = moment(this.totalHoras).format('mm');
      
      
                let differencia = moment(this.totalHoras).diff(this.auxTotalHoras);
      
                if ( minutos.toString().length === 0) {
                    minutos = '00';
                }

                if ( minutos.toString().length === 1) {
                    minutos = '0' + minutos.toString();
                }
                differencia = differencia / (1000 * 60 * 60);
                differencia = Math.abs(Math.floor(differencia));

                this.totalHoras = differencia + ':' + minutos;
      
                if ( contador === 0){
                    this.totalHoras = '00:00';
                }

            }
  
    
        });

        if ( contador === 0){
            this.totalHoras = '00:00';
        }
        this.filtering.filter();
    }

    getFechaInicio( event: string) {
        this.fecha.inicio = event;
        this.filtering.filter();
        this.updateFooter();
  
    }
    getFechaFin( event: string) {
        this.fecha.fin = event;
        this.filtering.filter();
        this.updateFooter();
    }

}
