<div *ngIf="distributionType === DISTRIBUTIONS.ROWS">
    <div *ngFor="let row of distributedFields; let i = index" class="row custom-col">
        <!-- <div *ngFor="let field of row" [ngStyle]="{'property': expression}"  [ngStyle]="field.style" class="col-sm-{{12 / (distributionOrder ? distributionOrder[i] || distributionColumns : distributionColumns)}}"> -->
        <div *ngFor="let field of row"  class="col-sm-{{12 / (distributionOrder ? distributionOrder[i] || distributionColumns : distributionColumns)}}">

            <div [ngClass]="(field.undoEnabled === true
                           || (undoEnabled === true && field.undoEnabled === undefined)) && model[field.field ?? 0] !== modelOld[field.field ?? 0]? 'edited' : ''">

                <label *ngIf="field.label !== undefined" style="display:flex;">
                    <i *ngIf="(field.undoEnabled === true
                               || (undoEnabled === true && field.undoEnabled === undefined))
                               &&  model[field.field ?? 0] !== modelOld[field.field ?? 0]"

                       pTooltip="Deshacer ({{modelOld[field.field ?? 0]}})"
                       tooltipPosition="top"
                       (click)="undoField(field)"
                       style="cursor: pointer"
                       class="fa fa-undo"
                       aria-hidden="true"></i>
                    {{field.label}}<span class="required-asterisk">{{field.required ? '&nbsp;*' : ''}}</span>
                    <div *ngIf="(field.inputType?.type === INPUT.MULTISELECT) && field.canSelectAll"
                        class="d-flex align-content-center small" style="margin-top: 0; ">
                        <span style="margin-top: 5px; margin-left: 5px;">Seleccionar todo 
                        <input #checkboxSelectAll type="checkbox" style="margin-left: .5rem; width: min-content !important;"
                            (change)="selectAllRows(field, checkboxSelectAll.checked)" />
                        </span>
                    </div>
                </label>


                <br *ngIf="field.label !== undefined">
                <input *ngIf="field.inputType?.type === INPUT.EDIT_TEXT" [(ngModel)]="model[field.field ?? 0]" (keyup)="markFieldAsChanged(field)" placeholder="..." pInputText type="text" maxlength={{field.maxLength}}/>
                <input *ngIf="field.inputType?.type === INPUT.PASSWORD" [(ngModel)]="model[field.field ?? 0]" (keyup)="markFieldAsChanged(field)" pInputText type="password" />
                <p-inputMask *ngIf="field.inputType?.type === INPUT.EDIT_TEXT_MASK" [(ngModel)]="model[field.field ?? 0]" (keyup)="markFieldAsChanged(field)" [mask]="field.mask" [placeholder]="field.mask">
                </p-inputMask>

                <div *ngIf="field.inputType?.type === INPUT.TWO_FIELDS_CONCAT" class="mt-0 d-flex justify-content-between">
                    <input [(ngModel)]="model[field.subField1 ?? 0]" (keyup)="markFieldAsChanged(field)" placeholder="..." pInputText type="number" />
                    <span class="mx-3">{{field?.separator || 'x'}}</span>
                    <input [(ngModel)]="model[field.subField2 ?? 0]" (keyup)="markFieldAsChanged(field)" placeholder="..." pInputText type="number" />
                </div>

                <p-select *ngIf="field.inputType?.type === INPUT.DROPDOWN" [(ngModel)]="field.values.selected" [options]="field.values.filtered" (onChange)="markFieldAsChanged(field);" [ngStyle]="field.style">
                </p-select>


                <p-select *ngIf="field.inputType?.type === INPUT.DROPDOWN_SEARCH" [(ngModel)]="field.values.selected"  [virtualScrollItemSize]="38" [options]="field.values.filtered" optionLabel="label" optionValue="value" [filter]="true" filterBy="label" (onChange)="markFieldAsChanged(field);">
                </p-select>

                <div *ngIf="field.inputType?.type === INPUT.RO_DROPDOWN" style="pointer-events:none;">
                    <p-select [(ngModel)]="field.values.selected" [options]="field.values.filtered" (onChange)="markFieldAsChanged(field);">
                    </p-select>
                </div>


                <p-listbox *ngIf="field.inputType?.type === INPUT.MULTISELECT"  [filter]="filterInputParcelas"  [multiple]="true" [metaKeySelection]="false" [checkmark]="true" [showToggleAll]="false"  [listStyle]="{'max-height' : field.maxHeight ? '100%' : '15rem','min-height':'18px'}" [(ngModel)]="field.values.selected"  [options]="field.values.filtered" (onChange)="markFieldAsChanged(field)">
                </p-listbox>


                <p-multiSelect *ngIf="field.inputType?.type === INPUT.DROPDOWN_MULTISELECT_SEARCH"
                    [options]="field.values.filtered"
                    [(ngModel)]="field.values.selected" optionLabel="label"
                    optionValue="value" [filter]="true" 
                    filterBy="label" [showToggleAll]="true" 
                    [selectAll]="selectAll"
                    (onSelectAllChange)="selectAllRows(field, $event.checked)"
                    (onChange)="markFieldAsChanged(field);" 
                    placeholder="Seleccionar">
                </p-multiSelect>

                <p-datePicker *ngIf="field.inputType?.type === INPUT.CALENDAR" [(ngModel)]="model[field.field ?? 0]" [firstDayOfWeek] = "1" dateFormat="dd/mm/yy" placeholder="..." appendTo="body" [view]="field?.calendarView || 'date'" (onSelect)="markFieldAsChanged(field)">
                </p-datePicker>

                <p *ngIf="field.inputType?.type === INPUT.TEXT" style="background-color: white;
                        border-radius: 3px;
                        border: 1px solid lightgray;
                        padding: 3px;">
                    {{model[field.field ?? 0] || '-' }}
                </p>

                <p *ngIf="field.inputType?.type === INPUT.ONLY_TEXT" [style]="'background-color: transparent;' + field.style">
                    {{model[field.field ?? 0] || '-' }}
                </p>

                <input *ngIf="field.inputType?.type === INPUT.EDIT_NUMERIC" [(ngModel)]="model[field.field ?? 0]" (keyup)="markFieldAsChanged(field)" placeholder="..." pInputText type="number" />

                <p-datePicker *ngIf="field.inputType?.type === INPUT.TIME" [(ngModel)]="model[field.field ?? 0]" [timeOnly]="true" placeholder="..." (onSelect)="markFieldAsChanged(field)">
                </p-datePicker>

                <textarea *ngIf="field.inputType?.type === INPUT.EDIT_TEXTAREA" [(ngModel)]="model[field.field ?? 0]" rows="{{field.rows}}" cols="{{field.cols}}" (keyup)="markFieldAsChanged(field)" placeholder="..." pTextarea>
                </textarea>

                <app-dictado 
                *ngIf="field.inputType?.type === INPUT.EDIT_TEXTAREA && field.audio == true"
                mensaje="Grabar {{field.label}}" 
                [observaciones]="model[field.field ?? 0]" 
                (sendObservaciones)="getAudio(field.field ?? '',$event)"
                ></app-dictado>
                
                    <!-- <app-upload-images 
                    [model]="model[field.field]" 
                    folder="limpieza"
                    nombre_imagen = 'image_after' 
                    tipo="form" 
                    (sendImageName)="updateAfterImageModel($event)"
                ></app-upload-images> -->

                <div *ngIf="field.inputType?.type === INPUT.RO_SWITCH" style="pointer-events:none;" >
                    
                    <p-inputSwitch
                            [(ngModel)]="model[field.field ?? 0]"
                            (change)="markFieldAsChanged(field)">
                    </p-inputSwitch>
                </div>
                <div *ngIf="field.inputType?.type === INPUT.RO_MD_SWITCH" style="pointer-events:none; position: relative; bottom: 20px" >
                    
                    <p-inputSwitch
                            [(ngModel)]="model[field.field ?? 0]"
                            (change)="markFieldAsChanged(field)">
                    </p-inputSwitch>
                </div>


                <textarea *ngIf="field.inputType?.type === INPUT.TEXTAREA" [(ngModel)]="model[field.field ?? 0]" rows="{{field.rows}}" cols="{{field.cols}}" (keyup)="markFieldAsChanged(field)" readonly placeholder="..." pTextarea>
                </textarea>

                <div *ngIf="field.inputType?.type === INPUT.EMPTY"></div>

                <!--<p-triStateCheckbox *ngIf="field.inputType?.type === INPUT.CHECK_BOX_TRISTATE" [(ngModel)]="model[field.field ?? 0]" [label]="field.label" (onChange)="markFieldAsChanged(field)">

                </p-triStateCheckbox>-->

                <p-inputSwitch *ngIf="field.inputType?.type === INPUT.SWITCH" [(ngModel)]="model[field.field ?? 0]" (onChange)="markFieldAsChanged(field)">
                </p-inputSwitch>

                <p-selectButton *ngIf="field.inputType?.type === INPUT.SELECT_BUTTON" 
                [(ngModel)]="field.values.selected" 
                [options]="field.values.filtered" (onChange)="markFieldAsChanged(field)" 
                [multiple]="field.multiSelect"
                [disabled]="field.disabled ?? false"
                >
                </p-selectButton>

                <ng-template *ngIf="field.inputType?.type === INPUT.EXTERNAL" dynamic-container></ng-template>
            </div>
        </div>
    </div>
</div>

<div *ngIf="distributionType === DISTRIBUTIONS.COLS">
    <div class="form-row custom-col">
        <div *ngFor="let col of distributedFields" class="from-group col-sm-{{12 / (distributionOrder ? distributionOrder[0] || distributionColumns : distributionColumns)}}">
            <div *ngFor="let field of col" [ngClass]="(field.undoEnabled === true
                           || (undoEnabled === true && field.undoEnabled === undefined)) && model[field.field ?? 0] !== modelOld[field.field ?? 0]? 'edited' : ''">

                <label *ngIf="field.hasLabel === true || field.hasLabel === undefined">
                    <i *ngIf="(field.undoEnabled === true
                               || (undoEnabled === true && field.undoEnabled === undefined))
                               &&  model[field.field ?? 0] !== modelOld[field.field ?? 0]"

                       pTooltip="Deshacer ({{modelOld[field.field ?? 0]}})"
                       tooltipPosition="top"
                       (click)="undoField(field)"
                       style="cursor: pointer"
                       class="fa fa-undo"
                       aria-hidden="true"></i>
                    {{field.label}}
                </label>
                <br *ngIf="field.hasLabel === true || field.hasLabel === undefined">
                <input *ngIf="field.inputType?.type === INPUT.EDIT_TEXT" [(ngModel)]="model[field.field ?? 0]" (keyup)="markFieldAsChanged(field)" placeholder="..." pInputText type="text" maxlength={{field.maxLength}}/>
                <input *ngIf="field.inputType?.type === INPUT.PASSWORD" [(ngModel)]="model[field.field ?? 0]" (keyup)="markFieldAsChanged(field)" pInputText type="password" />
                <p-inputMask *ngIf="field.inputType?.type === INPUT.EDIT_TEXT_MASK" [(ngModel)]="model[field.field ?? 0]" (keyup)="markFieldAsChanged(field)" [mask]="field.mask" [placeholder]="field.mask">
                </p-inputMask>

                <div *ngIf="field.inputType?.type === INPUT.TWO_FIELDS_CONCAT">
                    <input [(ngModel)]="model[field.subField1 ?? 0]" (keyup)="markFieldAsChanged(field)" placeholder="..." pInputText type="number" />
                    <span>{{field?.separator || 'x'}}</span>
                    <input [(ngModel)]="model[field.subField2 ?? 0]" (keyup)="markFieldAsChanged(field)" placeholder="..." pInputText type="number" />
                </div>

                <p-select *ngIf="field.inputType?.type === INPUT.DROPDOWN" [(ngModel)]="field.values.selected" [options]="field.values.filtered" (onChange)="markFieldAsChanged(field);">
                </p-select>



                <p-listbox *ngIf="field.inputType?.type === INPUT.MULTISELECT" multiple="multiple" checkbox="checkbox" [(ngModel)]="field.values.selected" [options]="field.values.filtered" (onChange)="markFieldAsChanged(field);">
                </p-listbox>

                <p-datePicker *ngIf="field.inputType?.type === INPUT.CALENDAR" [(ngModel)]="model[field.field ?? 0]" [locale]="es" [firstDayOfWeek] = "1"[dateFormat]="(field?.calendarView === 'month') ? 'mm/yyyy' : 'dd/mm/yy'" placeholder="..." [view]="field?.calendarView || 'date'" (onSelect)="markFieldAsChanged(field)">
                </p-datePicker>

                <p *ngIf="field.inputType?.type === INPUT.TEXT" style="background-color: white;
                        border-radius: 3px;
                        border: 1px solid lightgray;
                        padding: 3px;">
                    {{model[field.field ?? 0] || '-' }}
                </p>

                <input *ngIf="field.inputType?.type === INPUT.EDIT_NUMERIC" [(ngModel)]="model[field.field ?? 0]" (keyup)="markFieldAsChanged(field)" placeholder="..." pInputText type="number" />

                <p-datePicker *ngIf="field.inputType?.type === INPUT.TIME" [(ngModel)]="model[field.field ?? 0]" [timeOnly]="true" placeholder="..." (onSelect)="markFieldAsChanged(field)">
                </p-datePicker>

                <textarea *ngIf="field.inputType?.type === INPUT.EDIT_TEXTAREA" [(ngModel)]="model[field.field ?? 0]" rows="{{field.rows}}" cols="{{field.cols}}" (keyup)="markFieldAsChanged(field)" placeholder="..." pTextarea>
                </textarea>

                <textarea *ngIf="field.inputType?.type === INPUT.TEXTAREA" [(ngModel)]="model[field.field ?? 0]" rows="{{field.rows}}" cols="{{field.cols}}" (keyup)="markFieldAsChanged(field)" readonly placeholder="..." pTextarea>
                </textarea>

                <div *ngIf="field.inputType?.type === INPUT.EMPTY"></div>

                <div></div>

                <!--<p-triStateCheckbox *ngIf="field.inputType?.type === INPUT.CHECK_BOX_TRISTATE" [(ngModel)]="model[field.field ?? 0]" [label]="field.label" (onChange)="markFieldAsChanged(field)">

                </p-triStateCheckbox>-->

                <p-inputSwitch *ngIf="field.inputType?.type === INPUT.SWITCH" [(ngModel)]="model[field.field ?? 0]" (onChange)="markFieldAsChanged(field)">
                </p-inputSwitch>

                <ng-template #ext *ngIf="field.inputType?.type === INPUT.EXTERNAL" dynamic-container></ng-template>
            </div>
        </div>
    </div>
</div>
